@layer components {

	@screen md {

		.prose-image-portrait {
			display: block;
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}

	}

}
