@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./modules/base.css";
@import "./modules/components.banner.css";
@import "./modules/components.cards.css";
@import "./modules/components.header.css";
@import "./modules/components.foldout-menu.css";
@import "./modules/components.footer.css";
@import "./modules/components.hero.css";
@import "./modules/components.intro.css";
@import "./modules/components.language-menu.css";
@import "./modules/components.newsletter-signup.css";
@import "./modules/components.prose.css";
@import "./modules/components.prose-gallery.css";
@import "./modules/components.prose-image.css";
@import "./modules/components.prose-list.css";
@import "./modules/components.swiper.css";
@import "./modules/utilities.css";
