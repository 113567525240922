@layer components {

	.prose-list {
		background-color: var(--c-background);
		color: var(--c-text);

		ul:not(.unstyled) {
			border-spacing: 0 1lh;
		}

		h3, h4 {
			color: var(--c-title);
		}

		strong {
			color: var(--c-highlight);
		}

		a:not(.pill, .unstyled) {
			text-decoration-color: var(--c-highlight);
		}

	}

}
