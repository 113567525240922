@layer components {

	.basicLightbox.newsletter-lightbox {
		background: var(--c-background-themed, red);
	}

	.newsletter-signup-lightbox {
		@apply px-gutter;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		color: var(--c-text-themed);

		.lightbox-close {
			right: calc(-1rem + var(--gutter));
		}

		.lightbox-close::before,
		.lightbox-close::after {
			background: var(--c-highlight-themed, white);
		}
	}

	.newsletter-signup {
		@apply max-w-xl lg:max-w-lg mx-auto;
	}

	.newsletter-signup-intro {
		@apply text-lg;
	}

	.newsletter-signup-title + .newsletter-signup-intro,
	.newsletter-signup-success,
	[class*="newsletter-signup-error"],
	.newsletter-signup-form {
		margin-top: var(--fs,1lh);
	}

	.newsletter-signup-form {
		@apply flex items-baseline w-full pill-box;

		background: var(--c-white);
		color: var(--c-black);
	}

	.newsletter-signup-subscriber {
		flex-grow: 1;
		padding-right: 0.5em;
		background: transparent;
	}

	.block-newsletter {

		border-top-color: var(--c-highlight-themed);
		border-bottom-color: var(--c-highlight-themed);
		color: var(--c-white);

	}


	@media (hover:hover) {
		.newsletter-signup-form:has(.newsletter-signup-submit:hover) {
			background: var(--c-highlight-themed);
			color: var(--c-background-themed);

			.newsletter-signup-subscriber::placeholder {
				color: var(--c-background-themed);
			}
		}

	}

}
