@layer components {

	.block-banner {
		background-color: var(--c-background);
		border-top: 1px solid var(--c-border-top, var(--c-background));
		border-bottom: 1px solid var(--c-border-bottom, var(--c-background));
		color: var(--c-text);

		a:not(.pill, .unstyled) {
			text-decoration-color: var(--c-highlight);
		}

		strong {
			@apply font-wide;
			font-size: 0.8667em;
		}
	}

	.banner-title {
		color: var(--c-title);
	}

	.banner-cta {
		background-color: var(--c-button-fill);
		border-color: var(--c-button-border);
		color: var(--c-button-text);
	}

}
