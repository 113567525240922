@layer components {

	.footer {
		border-top: 1px solid var(--c-border-top, var(--c-light));
	}

	.footer-main {
		display: grid;
		grid-template-columns: 1fr 3fr;
		gap: var(--gutter);
		padding: calc(2 * var(--gutter)) var(--gutter);
		background: var(--c-dark);
		color: var(--c-white);


		.underline {
			text-decoration-color: var(--c-light);
		}
	}

	.footer-logo {
		width: min(4rem, calc(100% - var(--gutter)));
		height: auto;
	}

	.footer-columns {
		flex-grow: 1;
	}

	.footer-menu {
		display: block;
		columns: 2;
		margin: var(--gutter) 0;
	}

	.footer-web {

		grid-column: 2 / -1;

		display: flex;
		gap: 1lh;

		.footer-menu-social {
			display: flex;
			align-items: center;
			gap: 0.5em;
		}

		.footer-menu-social-item {
			@apply p-2 rounded-full;
		}

		.footer-newsletter-button {
			@apply h-8 text-base;
		}

		.footer-menu-social-item, .footer-newsletter-button {
			background: var(--c-white);
			border-color: var(--c-white);
			color: var(--c-dark);
			text-align: center;
		}

	}

	.footer-menu-sm {
		@apply text-xs;
		grid-column: 2 / -1; 
	}

	.footer-menu-sm-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;

		li {
			margin: 0 0.5em;
		}
	}

	@screen md {

		.footer-main {
			grid-template-columns: calc(10rem - var(--gutter)) 1fr;
		}

		.footer-logo {
			width: 5rem;
		}

		.footer-columns {
			display: grid;
			grid-template-columns: 3fr 4fr;
		}

		.footer-menu {
			margin: 0;
		}

	}

	@screen lg {

		.footer-main {
			grid-template-columns: 1fr calc( var(--linecap) - 2 * var(--gutter) ) 1fr;
			row-gap: 0;
		}

		.footer-web {
			grid-column: 3 / 3;
			flex-direction: column;
			align-items: center;

			.footer-menu-social-item {
				@apply p-1.5;
			}

			.footer-newsletter,
			.footer-newsletter-button {
				width: 100%;
				text-align: center;
			}
		}

		.footer-menu-sm {
			@apply text-xs;
			grid-column: 1 / -1; 
		}

		.footer-menu-sm-list {
			justify-content: center;
		}

	}
}
