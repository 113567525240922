@layer components {

	[data-cards] {
		opacity: 0;
	}

	.cards-header {
		display: flex;
		justify-content: space-between;
	}

	.cards-navigation {
		margin-left: auto;

		button {
			cursor: pointer;
		}

		svg {
			position: relative;
			top: -0.1em;
			display: inline-block;
			width: 1em;
			height: auto;
		}

		.swiper-button-disabled {
			opacity: 0.3;
			cursor: default;
		}
	}

	.cards {
		display: grid;
		gap: var(--gutter)
	}

	.swiper-initialized .cards {
		display: flex;
		gap: 0;
	}

	.cards-pagination {
		margin-top: 1lh;
		text-align: center;
	}


	.card-frame {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		width: 100%;
		height: auto;
		border-radius: var(--gutter);
		aspect-ratio: 4/3;
		background: var(--c-background-themed);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	@screen md {
		
		.cards {
			grid-template-columns: 1fr 1fr;
		}

	}

	@screen lg {
		
		.cards-sm {
			grid-template-columns: 1fr 1fr 1fr;
		}

	}

}

[data-cards].swiper-initialized {
	opacity: 1;
	overflow: hidden;
}
