@layer components {

	.hero-image {
		aspect-ratio: 4/3;
	}

	@screen md {

		.hero-image {
			aspect-ratio: 16/9;
		}

	}

	@screen xl {

		.hero-image {
			max-height: 750px;
			object-fit: cover;
			object-position: center;
		}

	}
}
