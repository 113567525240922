@layer components {

	.gallery {

		overflow: hidden;

		figure, img {
			height: 100%;
		}

		img {
			object-fit: contain;
			object-position: center;
			background: var(--c-grey);
			aspect-ratio: inherit !important;
		}
	}

	.gallery-navigation {
		display: flex;
		justify-content: space-between;
		margin-top: 0.5lh;
	}

	.gallery-prev,
	.gallery-next {
		cursor: pointer;

		svg {
			position: relative;
			top: -0.1em;
			display: inline-block;
			width: 1em;
			height: auto;
		}

		&.swiper-button-disabled {
			opacity: 0.3;
			cursor: default;
		}

	}

	.gallery-pagination {
		flex-grow: 1;
		text-align: center;
		text-wrap: balance;
	}

	/* This targets Safari, which isn't very good at text-wrap */
	@supports (-webkit-hyphens:none) {

		.gallery-pagination {
			text-wrap: wrap;
		}

	}

}
