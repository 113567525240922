button.swiper-bullet {
	@apply text-sm;
	width: 1.75em;
	height: 1.75em;
	line-height: 1.75em;
	color: var(--c-dark);
	border-radius: 999px;
	border: 1px solid var(--c-dark);
	background: transparent;
	opacity: 1;
}

button.swiper-bullet.swiper-pagination-bullet-active {
	background: var(--c-light);
}
