@layer utilities {

	.bg-inline {
		background-color: var(--c-background);
	}

	.underline {
		text-decoration-line: underline;
		text-decoration-thickness: max(.075rem, 1px);
		text-underline-offset: 0.2222rem;
	}

	.title {
		@apply font-wide;
		font-weight: bold;
		text-transform: uppercase;
		color: var(--c-dark);
	}

	.pill {
		padding: 0.4em 1em 0.3em;
		display: inline-block;
		background: var(--c-button-fill);
		border-radius: 9999px;
		border: 1px solid var(--c-button-border);
		color: var(--c-button-text);
		transition: translate .2s;
		cursor: pointer;
	}

	.pill-box {
		padding: 0.4em 1em 0.3em;
		border-radius: 9999px;
	}

	.visually-hidden {
		clip: rect(0 0 0 0); 
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap; 
		width: 1px;
	}

	.text-last-justify {
		text-align-last: justify;
	}

}
