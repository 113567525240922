@layer components {

	.foldout-menu {
		--foldout-menu-button-width: 2.1rem;
		--foldout-menu-button-height: 1.75rem;
		--foldout-menu-button-bar: 2px;
		padding-left: 1rem;
		padding-right: var(--foldout-menu-button-width);
	}

	.foldout-menu-button {
		position: fixed;
		top: calc(.6rem + var(--gutter));
		right: var(--gutter);
		z-index: 100;
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;
		box-sizing: content-box;
		width: var(--foldout-menu-button-width);
		height: var(--foldout-menu-button-height);
	}

	.foldout-menu-button span,
	.foldout-menu-button::before,
	.foldout-menu-button::after {
		content: "";
		overflow: hidden;
		display: block;
		width: 100%;
		height: var(--foldout-menu-button-bar);
		background: var(--c-button);
		border-radius: 999px;
		text-indent: -9999px;
		transition: rotate 0.2s;
	}

	.headroom--pinned.headroom--not-top {

		.foldout-menu-button {
			top: calc(.5 * var(--gutter) );
		}

		.foldout-menu-button span,
		.foldout-menu-button::before,
		.foldout-menu-button::after {
			background: var(--c-light);
		}

	}

	.headroom--unpinned .foldout-menu-button {
		display: none;
	}

	.foldout-menu-button[aria-expanded="true"] span {
		display: none;
	}

	.foldout-menu-button[aria-expanded="true"]::before,
	.foldout-menu-button[aria-expanded="true"]::after {
		position: absolute;
		top: calc( 50% - .5 * var(--foldout-menu-button-bar) );
		background: var(--c-foldout-button);
		rotate: 45deg;
		transform-origin: center;
	}

	.foldout-menu-button[aria-expanded="true"]::after {
		rotate: -45deg;
	}

	.foldout-menu-content {
		position: fixed;
		top: 0;
		right: -100%; 
		z-index: 99;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 80vw;
		height: 100vh;
		height: 100dvh;
		padding: calc(5 * var(--gutter) ) calc(2 * var(--gutter) ) var(--gutter);
		background: var(--c-foldout-background);
		color: var(--c-foldout-text);
		text-decoration-color: var(--c-foldout-highlight);
		border-left: 1px solid var(--c-foldout-button);
		transition: right .2s;

		ul {
			line-height: 1.5;
		}

		li {
			opacity: 1;
			transition-property: opacity;
			transition-duration: .3s;
		}
	}

	.header:not(.headroom--unpinned) .foldout-menu-button[aria-expanded='true'] + .foldout-menu-content {
		right: 0;

		li {
			opacity: 1;
		}
	}

	.foldout-menu-logo {
		position: absolute;
		right: var(--gutter);
		bottom: var(--gutter);
		width: 20%;
	}

	@screen md {

		.foldout-menu {
			--foldout-menu-button-width: 4.8vw;
			--foldout-menu-button-height: 4vw;
			--foldout-menu-button-bar: 0.2667rem;
		}

		.headroom--pinned.headroom--not-top {

			.foldout-menu {
				--foldout-menu-button-width: 2.5vw;
				--foldout-menu-button-height: 2vw;
				--foldout-menu-button-bar: 0.2rem;
			}

		}

		.foldout-menu-content {
			width: 65vw;
			padding: calc(5 * var(--gutter) ) calc(3 * var(--gutter) ) var(--gutter);

		}

	}

	@screen lg {

		.foldout-menu {
			--foldout-menu-button-width: 3.6vw;
			--foldout-menu-button-height: 3vw;
		}

		.foldout-menu-content {
			width: 50vw;
		}

	}

}
