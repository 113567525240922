@layer components {
	
	.block-intro {
		display: grid;
		grid-template-columns: 1fr 3fr;
		gap: var(--gutter);
		padding: calc(3 * var(--gutter)) var(--gutter);
		background-color: var(--c-background);
		border-top: 1px solid var(--c-border-top, var(--c-background));
		border-bottom: 1px solid var(--c-border-bottom, var(--c-background));
		color: var(--c-text);
	}

	.intro-logo {
		width: min(4rem, calc(100% - var(--gutter)));
		height: auto;
	}

	@screen md {

		.block-intro {
			grid-template-columns: calc(10rem - var(--gutter)) 1fr;
			align-items: center;
			padding: calc(2 * var(--gutter)) var(--gutter);
		}

		.intro-logo {
			width: 5rem;
		}

	}

	@screen lg {

		.block-intro {
			grid-template-columns: 1fr var(--linecap) 1fr;
		}

	}

}
