@layer components {

	.prose {
		--fs: 1em;

		* + h2,
		* + h3,
		* + figure,
		* + table,
		* + blockquote {
			margin-top: calc(2 * var(--fs, 1lh));
		}

		* + h4,
		* + p,
		* + ul.unstyled,
		* + iframe  {
			margin-top: var(--fs,1lh);
		}

		h4 + p {
			margin-top: 0;
		}

		/* headers */

		h2, h3, h4 {
			@apply font-wide;
			text-transform: uppercase;
		}

		h2 { font-size: 2.6667em; }
		h3 { font-size: 1em; }
		h4 { font-size: 0.8667em; }

		/* lists */

		ul:not(.unstyled),
		ol {
			display: table;
			border-collapse: separate;
			list-style-type: none;
		}

		ul:not(.unstyled) > li,
		ol > li {
			position: relative;
			display: table-row;
		}

		ul:not(.unstyled, .footnotes) > li::before {
			display: table-cell;
			width: 1.25em;
		}

		ul:not(.unstyled, .footnotes) > li::before {
			@apply absolute inline-block left-[-1em];
			content: "•";
			color: var(--c-highlight);
			font-size: 2em;
			line-height: 0.65em;
		}

		ol {
			counter-reset: item;
		}

		ol[start="2"]  { counter-reset: item 1; }
		ol[start="3"]  { counter-reset: item 2; }
		ol[start="4"]  { counter-reset: item 3; }
		ol[start="5"]  { counter-reset: item 4; }
		ol[start="6"]  { counter-reset: item 5; }
		ol[start="7"]  { counter-reset: item 6; }
		ol[start="8"]  { counter-reset: item 7; }
		ol[start="9"]  { counter-reset: item 8; }
		ol[start="10"] { counter-reset: item 9; }
		ol[start="11"] { counter-reset: item 10; }
		ol[start="12"] { counter-reset: item 11; }
		ol[start="13"] { counter-reset: item 12; }
		ol[start="14"] { counter-reset: item 13; }
		ol[start="15"] { counter-reset: item 14; }
		ol[start="16"] { counter-reset: item 15; }
		ol[start="17"] { counter-reset: item 16; }
		ol[start="18"] { counter-reset: item 17; }
		ol[start="19"] { counter-reset: item 18; }
		ol[start="20"] { counter-reset: item 19; }
		ol[start="21"] { counter-reset: item 20; }
		ol[start="22"] { counter-reset: item 21; }
		ol[start="23"] { counter-reset: item 22; }
		ol[start="24"] { counter-reset: item 23; }
		ol[start="25"] { counter-reset: item 24; }
		ol[start="26"] { counter-reset: item 25; }
		ol[start="27"] { counter-reset: item 26; }
		ol[start="28"] { counter-reset: item 27; }
		ol[start="29"] { counter-reset: item 28; }
		ol[start="30"] { counter-reset: item 29; }

		ol > li {
			counter-increment: item;
		}

		ol > li::before {
			content: counters(item, ".");
		}

		li ol > li::before {
			content: counters(item, ".") " ";
		}

		a:not(.unstyled) {
				text-decoration-color: var(--c-highlight);
		}
	}

}
