@layer components {

	.header {
		@apply py-gutter;

		background-color: var(--c-background);
		transform: translateY(0%);
	}

	.headroom {
		will-change: background-color, padding, transform;
		transition: background-color 200ms linear, padding 200ms linear, transform 200ms linear;
	}

	.headroom--pinned {
		transform: translateY(0%);
	}

	.headroom--unpinned {
		transform: translateY(-100%);
	}

	.header-logo {
		display: inline-block;
		width: 50vw;
		color: var(--c-dark);
	}

	.header-nav-primary {
		color: var(--c-dark);
		line-height: 0.8;

		.underline {
			text-decoration-color: var(--c-light);
		}
	}

	.header-nav-secondary {
		background-color: inherit;
		color: var(--c-dark);
		line-height: 0.8;

		.underline {
			text-decoration-color: var(--c-light);
		}

		.language-menu,
		.language-menu-content {
			background-color: inherit;
		}
	}

	.headroom--pinned.headroom--not-top {

		@apply py-gutter-1/2;

	}

	.headroom--pinned.headroom--not-top {

		.header-nav-primary {
			display: none;
		}

	}

	@screen max-sm {

		.header-nav-primary {
			display: none;
		}

	}

	@screen md {

		.header-logo {
			width: 40vw;
		}

		.foldout-menu {
			display: none;
		}

		.headroom--pinned.headroom--not-top {

			.header-logo {
				width: 20vw;
			}

			.language-menu {
				display: none;
			}

			.foldout-menu {
				display: initial;
			}

		}

	}

	@screen lg {

		.header-logo {
			width: 30vw;
		}

	}

}
