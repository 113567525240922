@layer components {

	.language-menu {
		position: relative;
	}

	.language-menu-content {
		position: absolute;
		left: calc( -0.5 * var(--gutter) );
		width: 100%;
		box-sizing: content-box;
		padding: 0 calc( .5 * var(--gutter) ) calc( .75 * var(--gutter) );
		visibility: hidden;

		li {
			opacity: 0;
			transition-property: opacity;
			transition-duration: .3s;
		}

		li:nth-child(2) { transition-delay: .1s; }
		li:nth-child(3) { transition-delay: .2s; }
		li:nth-child(4) { transition-delay: .3s; }
		li:nth-child(5) { transition-delay: .4s; }
	}

	.language-menu-button[aria-expanded='false']:focus + .language-menu-content {
		visibility: hidden;
	}

	.language-menu-button:focus + .language-menu-content,
	.language-menu:focus-within .language-menu-content {
		visibility: visible;

		li {
			opacity: 1;
		}
	}

	.headroom--pinned.headroom--not-top .language-menu-content {
		background: var(--c-dark);
	}

	.foldout-language-menu .underline {
		text-decoration-color: var(--c-dark);
	}

	.language-menu .underline {
		text-decoration-color: var(--c-light);
	}

}
